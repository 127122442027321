import { CSSProperties, Children, FC } from 'react';
import { colors } from 'variables';
import Select, {
  ActionMeta,
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  MultiValue,
  OptionProps,
  StylesConfig,
  ValueContainerProps,
} from 'react-select';
import { Checkbox } from '../checkbox';
import { ArrowDownDropdownSolidIcon, ArrowUpDropdownSolidIcon, CloseSelectIcon } from 'assets/icons';

export const SelectCheckboxStyles: StylesConfig<any, true> = {
  control: (styles, { isFocused, selectProps: { error } }: any) => ({
    ...styles,
    minHeight: '42px',
    backgroundColor: 'white',
    flexWrap: 'nowrap',
    borderRadius: '4px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: error ? colors.red.r1 : isFocused ? colors.blue.b1 : colors.gray.g2,
    boxShadow: 'none',
    '&:hover': {
      borderColor: error ? colors.red.r1 : isFocused ? colors.blue.b1 : colors.gray.g2,
    },
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: colors.gray.gm,
    fontSize: '12px',
    marginLeft: 0,
    marginRight: 0,
    display: state.isFocused ? 'none' : '',
  }),
  input: (styles) => ({
    ...styles,
    margin: 0,
    'input:focus': {
      boxShadow: 'none',
    },
  }),
  option: (styles) => ({
    ...styles,
    padding: '6px 16px',
    fontSize: '14px',
    color: colors.black.b1,
    backgroundColor: colors.white.default,
    '&:hover': {
      backgroundColor: colors.gray.g1,
    },
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 1000,
    marginTop: '4px',
    boxShadow: 'var(--shadow-s1)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.gray.g1,
  }),
  menuList: (styles) => ({ ...styles, padding: '10px 0' }),
  valueContainer: (styles) => ({
    ...styles,
    display: 'flex',
    fontSize: '12px',
    color: colors.black.b1,
    paddingLeft: '12px',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: colors.blue.light,
    borderRadius: '4px',
    padding: '0 10px',
    // margin: '4px 8px 4px 0',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontSize: '12px',
    color: colors.blue.b1,
    '&:hover': {
      backgroundColor: colors.blue.light,
    },
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: colors.blue.b1,
    '&:hover': {
      backgroundColor: colors.blue.light,
      color: colors.blue.b1,
    },
  }),
};

export const settingsSelectCheckboxMultiStyles: StylesConfig<any, true> = {
  ...SelectCheckboxStyles,
  placeholder: (styles, props) => ({
    ...(SelectCheckboxStyles?.placeholder ? SelectCheckboxStyles?.placeholder(styles, props) : {}),
    color: colors.black.b1,
  }),
  valueContainer: (styles, props) => ({
    ...(SelectCheckboxStyles?.valueContainer ? SelectCheckboxStyles?.valueContainer(styles, props) : {}),
    input: {
      position: 'absolute',
    },
  }),
};

export const settingsSelectCheckboxMultiWithSearchStyles: StylesConfig<any, true> = {
  ...SelectCheckboxStyles,
  placeholder: (styles, props) => ({
    ...(SelectCheckboxStyles?.placeholder ? SelectCheckboxStyles?.placeholder(styles, props) : {}),
    color: colors.black.b1,
  }),
  valueContainer: (styles, props) => ({
    ...(SelectCheckboxStyles?.valueContainer ? SelectCheckboxStyles?.valueContainer(styles, props) : {}),
    position: 'relative',
    display: 'grid',
  }),
  input: (styles, props) => ({
    ...(SelectCheckboxStyles?.input ? SelectCheckboxStyles?.input(styles, props) : {}),
    margin: 0,
    'input:focus': {
      outline: 'none',
      boxShadow: 'none',
      borderWidth: 0,
    },
  }),
  menu: (styles, props) => ({
    ...(SelectCheckboxStyles?.menu ? SelectCheckboxStyles?.menu(styles, props) : {}),
    minWidth: '15rem',
  }),
};

const Option = ({ children, ...props }: OptionProps<any, true>) => {
  const { isSelected } = props;

  return (
    <components.Option {...props}>
      <div className="flex space-x-2.5">
        <Checkbox checked={isSelected} style={{ marginTop: '1px' }} onChange={() => {}} />
        <label className="whitespace-pre-wrap">{props.label}</label>
      </div>
    </components.Option>
  );
};

const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => {
  const { menuIsOpen } = props.selectProps;
  return (
    <components.DropdownIndicator {...props} className="!pl-0">
      {menuIsOpen ? <ArrowUpDropdownSolidIcon /> : <ArrowDownDropdownSolidIcon />}
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props: ClearIndicatorProps<any, true>) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  }: any = props;

  return (
    <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props) as CSSProperties} className="!px-0 cursor-pointer">
      <CloseSelectIcon />
    </div>
  );
};

export const ValueContainer = ({ children, ...props }: ValueContainerProps<any>) => {
  const _children = Children.toArray(children);
  const isAll = props.selectProps.value.some((option: any) => option.value === 'all');

  return (
    <components.ValueContainer {...props}>
      {props.selectProps.value.length ? (
        <div className="truncate">{isAll ? 'All' : props.selectProps.value.map((d: any) => d.label).join(', ')}</div>
      ) : (
        _children[0]
      )}
      {_children[_children.length - 1]}
    </components.ValueContainer>
  );
};

export const ValueContainerWithSearch = ({ children, ...props }: ValueContainerProps<any>) => {
  const isAll = props.selectProps.value.some((option: any) => option.value === 'all');

  return (
    <components.ValueContainer {...props} className="relative">
      {props.selectProps.value.length && !props.selectProps.menuIsOpen ? (
        <div className="absolute left-3 right-0 truncate text-black-b1">
          <p className="truncate">Selected {isAll ? 'all' : props.selectProps.value.length} items</p>
        </div>
      ) : null}
      {children}
    </components.ValueContainer>
  );
};

interface ISelectCheckboxProps {
  name: string;
  options: any[];
  onChange: (newValue: MultiValue<any>, actionMeta: ActionMeta<any>) => void;
  defaultValue?: any;
  [prop: string]: any;
}

export const SelectCheckbox: FC<ISelectCheckboxProps> = ({
  name = 'select-checkbox',
  options = [],
  defaultValue,
  onChange = () => {},
  isSearchable = false,
  ...rest
}) => {
  return (
    <Select
      defaultValue={defaultValue || [options[0]]}
      name={name}
      isSearchable
      isClearable={false}
      closeMenuOnSelect={false}
      isMulti
      hideSelectedOptions={false}
      menuPlacement="auto"
      // menuIsOpen
      options={options}
      styles={{ ...SelectCheckboxStyles, ...(rest.styles || {}) }}
      onChange={onChange}
      {...rest}
      components={{
        ClearIndicator,
        IndicatorSeparator: () => null,
        DropdownIndicator,
        Option,
        ...(rest.components || {}),
      }}
    />
  );
};
