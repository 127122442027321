import { FC } from 'react';

export enum CampaignPerformanceSwitcherOptions {
  Overall,
  ByChannel,
}

interface ICampaignPerformanceSwitcher {
  selected: CampaignPerformanceSwitcherOptions;
  onSelect: (option: CampaignPerformanceSwitcherOptions) => void;
}

export const CampaignPerformanceSwitcher: FC<ICampaignPerformanceSwitcher> = ({ selected, onSelect }) => {
  const commonClasses: string = 'h-9 px-3 font-medium text-sm leading-6';
  const activeClasses: string = 'bg-blue-bl text-blue-b1';
  const inactiveClasses: string = 'border-y border-gray-g2 text-black-b1';

  const overallClasses: string = (selected === CampaignPerformanceSwitcherOptions.Overall)
    ? `${activeClasses} rounded-l`
    : `${inactiveClasses} border-l rounded-l`;

  const byChannelClasses: string = (selected === CampaignPerformanceSwitcherOptions.ByChannel)
    ? `${activeClasses} rounded-r`
    : `${inactiveClasses} border-r rounded-r`;

  return (
    <div>
      <button
        onClick={() => onSelect(CampaignPerformanceSwitcherOptions.Overall)}
        className={`${commonClasses} ${overallClasses}`}
      >
        Overall
      </button>

      <button
        onClick={() => onSelect(CampaignPerformanceSwitcherOptions.ByChannel)}
        className={`${commonClasses} ${byChannelClasses}`}
      >
        By channel
      </button>
    </div>
  );
};
