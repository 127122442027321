import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CustomizedXAxisTick, CustomizedYAxisTick, CustomTooltip } from '../tabs/Report';
import { colors } from 'variables';
import moment from 'moment';
import { formatNumber } from 'utils/helper';
import { FC, useMemo } from 'react';
import { maxBy } from 'lodash';
import { CampaignPerformanceSwitcher, CampaignPerformanceSwitcherOptions } from './CampaignPerformanceSwitcher';

interface ICampaignPerformanceOverTime {
  data: any[];
  onSwitch: (option: CampaignPerformanceSwitcherOptions) => void;
}

export const CampaignPerformanceOverTime: FC<ICampaignPerformanceOverTime> = ({ data, onSwitch }) => {
  const interval = Math.floor(data.length / 5);
  const lineChartTicks = [];

  if (interval > 0) {
    for (let i = 1; i < 5; i++) {
      lineChartTicks.push(data[i * interval].date);
    }
  }

  const performanceLeftMargin = useMemo(
    () => (maxBy(data, 'video_views')?.video_views > 999 ? -10 : -20),
    [data]
  );

  return (
    <div>
        <div className="mt-11 flex">
          <p className="flex-grow text-base font-semibold text-black-b1">
            Campaign Performance Over Time
          </p>

          <div>
            <CampaignPerformanceSwitcher selected={CampaignPerformanceSwitcherOptions.Overall} onSelect={onSwitch} />
          </div>
        </div>

        <div className="mt-6">
          <ResponsiveContainer width={'100%'} height={200}>
            <LineChart
              data={data}
              margin={{
                top: 15,
                right: 5,
                left: performanceLeftMargin,
                bottom: 5,
              }}
            >
              <CartesianGrid
                vertical={false}
                stroke={colors.gray.gm}
                strokeDasharray="2 5"
              />

              <XAxis
                dataKey="date"
                type="number"
                domain={['auto', 'auto']}
                scale="time"
                axisLine={false}
                tickLine={false}
                ticks={lineChartTicks}
                // interval={4}
                tick={<CustomizedXAxisTick format={(date) => moment.unix(date).format('MMM DD')} fontSize="10px" fill={colors.gray.gm} />}
              />

              <YAxis
                type="number"
                domain={['auto', 'auto']}
                axisLine={false}
                tickLine={false}
                tickMargin={6}
                tick={<CustomizedYAxisTick fontSize="10px" fill={colors.gray.gm} format={(y: any) => formatNumber(y, '0.0a')} />}
              />

              <Tooltip
                content={<CustomTooltip />}
              />

              <Line
                // type="monotone"
                dataKey="video_views"
                stroke={colors.blue.b1}
                strokeWidth={3}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
    </div>
  );
};
