import { maxBy } from 'lodash';
import { FC, useMemo, useState } from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { colors } from 'variables';
import { CustomizedXAxisTick, CustomizedYAxisTick } from '../tabs/Report';
import moment from 'moment';
import { formatNumber } from 'utils/helper';
import { CampaignPerformanceSwitcher, CampaignPerformanceSwitcherOptions } from './CampaignPerformanceSwitcher';

const buttonColors: string[] = [
  '#00BF7D',
  '#2546F0',
  '#5928ED',
  '#C44601',
  '#89CE00',
  '#F57600',
  '#E6308A',
  '#B51963',
  '#9B8BF4',
  '#FAAF90',
  '#009EB0',
  '#C18486',
  '#7B0A65',
  '#BB795C',
  '#ABC4CA',
  '#82A4AC',
  '#F4DADB',
  '#CCC38D',
  '#004450',
  '#188500',
  '#AC9D00',
];

interface IChannelButton {
  color: string;
  id: string;
  name: string;
  onToggle: (id: string) => void;
}

const ChannelButton: FC<IChannelButton> = ({ color, id, name, onToggle }) => {
  const [selected, setSelected] = useState<boolean>(false);

  const toggle = () => {
    setSelected(!selected);
    onToggle(id);
  };

  return (
    <button className={`h-9 flex flex-row justify-center items-center px-2.5 gap-2.5 rounded ${selected ? 'bg-blue-bl' : 'bg-gray-g1'}`} onClick={toggle}>
      <div className='w-1.5 h-1.5 rounded-full' style={{ backgroundColor: color }}></div>
      <div className='text-sm leading-6 text-black'>{name}</div>
    </button>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload, label } = props;

  if (active && payload && payload.length) {
    return (
      <div className="shadow p-2 bg-white">
        <div className="flex items-center space-x-2">
          <p className="text-gray-g3">Date:</p>
          <p className="text-gray-gm">{moment.unix(label).format('MMM DD')}</p>
        </div>

        {payload.sort((a: any, b: any) => b.value - a.value).map((p: any) => (
          <div className="flex items-center space-x-2">
            <p className="text-gray-g3">{p.name}:</p>
            <p style={{ color: p.color }}>{p.value}</p>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

interface ICampaignPerformanceByChannels {
  data: any[];
  onSwitch: (option: CampaignPerformanceSwitcherOptions) => void;
}

export const CampaignPerformanceByChannels: FC<ICampaignPerformanceByChannels> = ({ data, onSwitch }) => {
  const performanceLeftMargin: number = useMemo(
    () => (maxBy(data, 'video_views')?.video_views > 999 ? -10 : -20),
    [data]
  );

  const interval: number = Math.floor(data.length / 5);
  const lineChartTicks: string[] = [];

  if (interval > 0) {
    for (let i = 1; i < 5; i++) {
      lineChartTicks.push(data[i * interval].date);
    }
  }

  const groupedData: any[] = data.reduce(
    (result: any[], value: any) => {
      const index = result.findIndex(element => element.date === value.date);

      if (index === -1) {
        result.push({
          date: value.date,
          data: [value]
        });
      } else {
        result[index].data.push(value);
      }

      return result;
    },
    []
  );

  const dataKey = (id: string) => (
    (obj: any) => obj.data.find((el: any) => el.youtube_channel_id === id)?.video_views
  );

  const [filter, setFilter] = useState<string[]>([]);

  const toggleFilter = (id: string) => {
    if (filter.indexOf(id) === -1) {
      setFilter(filter.concat([id]));
    } else {
      setFilter(filter.filter(_id => _id !== id));
    }
  };

  const channels: any[] = data.reduce(
    (result: any, value: any) => {
      if (result.find((el: any) => el.id === value.youtube_channel_id)) {
        return result;
      } else {
        return result.concat([{
          id: value.youtube_channel_id,
          name: value.channel_name,
          color: buttonColors[result.length % buttonColors.length],
          drawn: (filter.length === 0 || filter.includes(value.youtube_channel_id))
        }]);
      }
    },
    []
  );

  return (
    <div>
      <div className='mt-11 flex gap-2.5'>
        {channels.map(channel => (
          <ChannelButton
            color={channel.color}
            id={channel.id}
            name={channel.name}
            onToggle={toggleFilter}
          />
        ))}
      </div>

      <div className="mt-6 flex">
        <div className="flex-grow text-base font-semibold text-black-b1">
          Campaign Performance By Channel
        </div>

        <div>
          <CampaignPerformanceSwitcher selected={CampaignPerformanceSwitcherOptions.ByChannel} onSelect={onSwitch} />
        </div>
      </div>

      <div className="mt-6">
        <ResponsiveContainer height={200}>
          <LineChart
            data={groupedData}
            margin={{
              top: 15,
              right: 5,
              left: performanceLeftMargin,
              bottom: 5,
            }}
          >
            <CartesianGrid
              vertical={false}
              stroke={colors.gray.gm}
              strokeDasharray="2 5"
            />

            <XAxis
              dataKey="date"
              type="number"
              domain={['auto', 'auto']}
              scale="time"
              axisLine={false}
              tickLine={false}
              ticks={lineChartTicks}
              tick={<CustomizedXAxisTick format={(date) => moment.unix(date).format('MMM DD')} fontSize="10px" fill={colors.gray.gm} />}
            />

            <YAxis
              type="number"
              domain={['auto', 'auto']}
              axisLine={false}
              tickLine={false}
              tickMargin={6}
              tick={<CustomizedYAxisTick fontSize="10px" fill={colors.gray.gm} format={(y: any) => formatNumber(y, '0.0a')} />}
            />

            <Tooltip
              content={<CustomTooltip />}
            />

            {channels.map(channel => (
              <Line
                key={`channel_line_for_${channel.id}`}
                dataKey={dataKey(channel.id)}
                name={channel.name}
                stroke={channel.color}
                strokeWidth={3}
                dot={false}
                hide={!channel.drawn}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
