import { FC, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { StylesConfig } from 'react-select';
import cn from 'classnames';
import { Form, Input, Label, RSelect, SelectCheckbox, RSelectStyles, ClipLoader, settingsSelectCheckboxMultiStyles } from 'shared';
import { ModalState } from 'components/Modal/reducer';
import { ModalHeader } from '../../index';
import { useCountriesSelectOptions } from 'hooks/useCountries';
import { ValidationTypes } from '../../../../utils/validation';
import { colors } from '../../../../variables';

const selectStyles: StylesConfig<any, false> = {
  ...RSelectStyles,
  control: (styles, props) => ({
    ...(RSelectStyles?.control ? RSelectStyles?.control(styles, props) : {}),
    minHeight: '48px !important',
  }),
};

const selectCountryMultiStyles: StylesConfig<any, true> = {
  ...settingsSelectCheckboxMultiStyles,
  control: (styles, props) => ({
    ...(settingsSelectCheckboxMultiStyles?.control ? settingsSelectCheckboxMultiStyles?.control(styles, props) : {}),
    minHeight: '48px !important',
  }),
  placeholder: (styles, props) => ({
    ...(settingsSelectCheckboxMultiStyles?.placeholder ? settingsSelectCheckboxMultiStyles?.placeholder(styles, props) : {}),
    color: colors.gray.gm,
    fontSize: '12px',
  }),
};

const agencyTypeOptions: any[] = [
  { value: 'Talent Agency', label: 'Talent Agency' },
  { value: 'Influencer Agency', label: 'Influencer Agency' },
  { value: 'PR Agency', label: 'PR Agency' },
  { value: 'Other', label: 'Other' },
];

const agencyFocusOptions: any[] = [
  { value: 'Gaming', label: 'Gaming' },
  { value: 'Entertainment', label: 'Entertainment' },
  { value: 'Sport', label: 'Sport' },
  { value: 'Tech Reviews', label: 'Tech Reviews' },
  { value: 'Other', label: 'Other' },
];

interface IAddAgencieProps extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}

export const AddAgency: FC<IAddAgencieProps> = ({ params, hideModal }) => {
  const countriesOptions = useCountriesSelectOptions();
  const isUpdate = Boolean(params?.agency?.agency_id);

  const defaultValues: any = useMemo(
    () => ({
      agency_name: params?.agency?.agency_name || '',
      agency_website: params?.agency?.agency_website || '',
      agency_country: params?.agency?.agency_country.split(',') || [],
      agency_type: params?.agency?.agency_type || '',
      agency_focus: params?.agency?.agency_focus || '',
    }),
    [params?.agency]
  );

  return (
    <div className="flex h-full flex-col">
      <ModalHeader title={isUpdate ? 'Update agency' : 'Add agency'} close={hideModal} />
      <Form
        className="flex-1 space-y-5 p-6 pt-0 sm:p-8 sm:pt-0"
        defaultValues={defaultValues}
        validationType={ValidationTypes.AdminAddAgency}
        onSubmit={(values) => params.handleSave(values).then(() => hideModal())}
      >
        {({ register, control, formState }) => {
          const { errors, isSubmitting } = formState;
          return (
            <>
              <div>
                <Label htmlFor="agency_name" label="Name" error={errors.agency_name} />
                <div className="mt-0.5">
                  <Input id="agency_name" type="text" placeholder="Agency name" error={errors.agency_name} {...register('agency_name')} />
                </div>
              </div>

              <div>
                <Label htmlFor="agency_website" label="Website" error={errors.agency_website} />
                <div className="mt-0.5">
                  <Input id="agency_website" type="text" placeholder="Agency website" error={errors.agency_website} {...register('agency_website')} />
                </div>
              </div>

              <div>
                <Label htmlFor="agency_country" label="Country" error={errors.agency_country} />
                <div className="mt-0.5">
                  <Controller
                    name="agency_country"
                    control={control}
                    render={({ field }: any) => (
                      <SelectCheckbox
                        {...field}
                        styles={selectCountryMultiStyles}
                        placeholder="Not selected"
                        options={countriesOptions}
                        error={errors.agency_country}
                        defaultValue={countriesOptions.filter((country: any) => field.value.includes(country.value)) || null}
                        value={countriesOptions.filter((country: any) => field.value.includes(country.value)) || null}
                        onChange={(option: any) => {
                          field.onChange(option.map((d: any) => d.value));
                        }}
                        isSearchable={true}
                      />
                    )}
                  />
                </div>
              </div>

              <div>
                <Label htmlFor="agency_type" label="Type" error={errors.agency_type} />
                <div className="mt-0.5">
                  <Controller
                    name="agency_type"
                    control={control}
                    render={(props: any) => {
                      const { field } = props;
                      return (
                        <RSelect
                          {...field}
                          styles={selectStyles}
                          placeholder="Not Selected"
                          options={agencyTypeOptions}
                          error={errors.agency_type}
                          components={{
                            ClearIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                          value={agencyTypeOptions.find((option) => option.value === field.value) || null}
                          onChange={(option: any) => {
                            field.onChange(option.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>

                <div>
                  <Label htmlFor="agency_focus" label="Focus" error={errors.agency_focus} />
                  <div className="mt-0.5">
                    <Controller
                      name="agency_focus"
                      control={control}
                      render={(props: any) => {
                        const { field } = props;
                        return (
                          <RSelect
                            {...field}
                            styles={selectStyles}
                            placeholder="Not Selected"
                            options={agencyFocusOptions}
                            error={errors.agency_focus}
                            components={{
                              ClearIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            value={agencyFocusOptions.find((option) => option.value === field.value) || null}
                            onChange={(option: any) => {
                              field.onChange(option.value);
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-8">
                <button
                  type="button"
                  className="flex w-full h-12 items-center justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none focus:ring-0"
                  onClick={hideModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={cn(
                    'flex w-full h-12 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0',
                    { disabled: isSubmitting }
                  )}
                  disabled={isSubmitting}
                >
                  {isUpdate ? <p>Save</p> : <p>Create</p>}
                  <ClipLoader loading={isSubmitting} size={20} styles={{ borderWidth: '2px' }} />
                </button>
              </div>
            </>
          );
        }}
      </Form>
    </div>
  );
};
